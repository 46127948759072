import { chains } from '@/config/chains';
import React, { FC } from 'react';
import { PrivyProvider } from '@privy-io/react-auth';

interface IPrivyProps {
    children: React.ReactNode;
}

const Privy: FC<IPrivyProps> = ({ children }) => {
    // TODO: add theme support for the privy modals (move privy provider inside redux provider)
    // const [theme] = useDarkMode();

    return (
        <PrivyProvider
            appId={process.env.NEXT_PUBLIC_PRIVY_APP_ID ?? ''}
            config={{
                captchaEnabled: true,
                loginMethods: ['email', 'wallet', 'google', 'discord', 'telegram'],
                appearance: {
                    theme: 'light',
                    logo: 'https://yellow.com/logo.webp',
                },
                embeddedWallets: {
                    createOnLogin: 'all-users',
                    noPromptOnSignature: true,
                },
                legal: {
                    termsAndConditionsUrl: 'https://yellow.com/terms_of_service',
                    privacyPolicyUrl: 'https://yellow.com/privacy_policy',
                },
                supportedChains: chains,
            }}>
            {children}
        </PrivyProvider>
    );
};

export default Privy;
